// app/javascript/controllers/hub_opportunity_wizard_controller.js
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    richHtml: String,
    plainText: String
  }

  connect() {
    console.log("hello accordian controller")
    this.richTarget = document.getElementById("rich-text-content")
    this.plainTarget = document.getElementById("plain-text-content")

    if (this.richTarget && this.hasRichHtmlValue) {
      this.richTarget.innerHTML = this.richHtmlValue
    }

    if (this.plainTarget && this.hasPlainTextValue) {
      this.plainTarget.textContent = this.plainTextValue
    }
  }

  async copyRich() {
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([this.richHtmlValue], { type: "text/html" }),
          "text/plain": new Blob([this.richHtmlValue], { type: "text/plain" })
        })
      ])
      alert("Copied rich text!")
    } catch (e) {
      console.error(e)
      alert("Failed to copy rich text.")
    }
  }

  async copyPlain() {
    try {
      await navigator.clipboard.writeText(this.plainTextValue)
      alert("Copied plain text!")
    } catch (e) {
      console.error(e)
      alert("Failed to copy plain text.")
    }
  }
}
